import Countdown from "react-countdown"
import React, { useEffect, useMemo, useRef, useState } from "react"
import moment from "moment"
import capitalize from "lodash/capitalize"
import { Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SimpleTable from "../components/common/tables/simple"
import { Game } from "../subscriptions/games"
import { GameState, completeGameAction, fetchGameAction, gameSelector, startGameAction } from "../hasura/slices/game"
import { GameStatus } from "../lib/gameStatus"
import { gameIsComplete, navigateToLeaderboard, parseIdFrom, pluralize, queryParams } from "../lib/helpers"
import { userSelector, UserState } from "../hasura/slices/user"

const colorForGame = (game?: Game) => {
  switch (game?.status) {
    case GameStatus.NotStarted:
      return "text--gray8"
    case GameStatus.InProgress:
      return "text--success"
    case GameStatus.Finished:
      return "text--primary"
  }
}

const BUFFER_SECONDS = 10

export default function AdminGame() {
  const dispatch = useDispatch()

  const { accessToken }: UserState = useSelector(userSelector)
  const { game }: GameState = useSelector(gameSelector)

  const [displayCountdown, setDisplayCountdown] = useState(false)
  const fetchGameInterval = useRef<any | null>(null)

  const participantColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "user.display_name",
      },
      {
        Header: "Points",
        accessor: "score",
      },
    ],
    []
  )

  useEffect(() => {
    const id = parseInt(queryParams()?.id || "")
    if (!id || !accessToken) return

    clearInterval(fetchGameInterval.current)
    const fetchGame = () => dispatch(fetchGameAction(accessToken, parseIdFrom(accessToken)!, id))
    fetchGameInterval.current = setInterval(fetchGame, 5000)
    fetchGame()
    return () => {
      clearInterval(fetchGameInterval.current)
    }
  }, [accessToken])

  const handleGameComplete = () => {
    navigateToLeaderboard(game)
    dispatch(completeGameAction(accessToken!, game!.id))
  }

  const handleStartGame = () => {
    dispatch(startGameAction(accessToken!, game!.id, BUFFER_SECONDS))
    setTimeout(() => setDisplayCountdown(true), BUFFER_SECONDS * 1000)
  }

  const participantsCount = game?.positions.length || 0
  const hasParticipants = participantsCount > 0

  return (
    <Layout>
      <SEO title="Admin Game" />

      {game && (
        <div className="max-width-900px mx-auto pb-5">
          <h1 className="header page-top ">
            {game.root?.display_name.toUpperCase()} | {pluralize("minute", game.minutes)}
          </h1>

          <div style={{ height: "1px" }} className="w-100 mt-3 bg--gray3" />

          <div className="my-4">
            <p className="bold text-s m-0 gray8">Game code</p>

            <p style={{ fontSize: "8em", lineHeight: "140px" }} className="bold text-primary m-0">
              {game.join_code}
            </p>
          </div>

          <div style={{ height: "1px" }} className="w-100 bg--gray3" />

          <div className="my-4">
            <p className="bold text-s m-0 gray8">Time</p>

            <div className="d-flex align-items-center">
              {game.started_at && displayCountdown ? (
                <Countdown
                  intervalDelay={100}
                  date={moment(game!.started_at).unix() * 1000 + (game!.minutes || 0) * 60 * 1000}
                  onComplete={handleGameComplete}
                  onStart={(props) => {
                    if (props.completed || gameIsComplete(game)) navigateToLeaderboard(game)
                  }}
                  renderer={(props) => {
                    const { minutes, seconds, completed } = props
                    const time = completed ? "0:00" : `${minutes}:${seconds < 10 ? 0 : ""}${seconds}`
                    return (
                      <p style={{ width: "100px" }} className="text-xxl m-0">
                        {time}
                      </p>
                    )
                  }}
                />
              ) : (
                <p style={{ width: "100px" }} className="text-xxl m-0">
                  {game.minutes}:00
                </p>
              )}

              {!game.started_at && (
                <Button size="lg" disabled={!hasParticipants} onClick={handleStartGame} color="success">
                  Start Game
                </Button>
              )}

              <p className={`m-0 ml-2 text-s ${colorForGame(game)}`}>
                {game.status === GameStatus.InProgress && !displayCountdown ? "Preparing game" : capitalize(game.status)}
              </p>
            </div>
          </div>

          <div style={{ height: "1px" }} className="w-100 bg--gray3" />

          <div style={{ maxWidth: "600px" }} className="my-4">
            <p className="bold text-s m-0 gray8">Participants</p>

            {hasParticipants && <SimpleTable columns={participantColumns} data={game!.positions} />}

            {hasParticipants && <p className={`m-0 text-s mt-2 text--gray8`}>{pluralize("participant", participantsCount)}</p>}

            {!hasParticipants && (
              <div className="mt-2">
                <p className="m-0 text-m flex-grow">
                  Student(s) must be logged into Wordcraft and join with code <span className="bold text--primary">{game.join_code}</span> from their
                  home screens before game can be started.
                  <span
                    onClick={() => window.open("https://intercom.help/playwordcraft/en/articles/6229554-multiplayer-games", "_blank")}
                    className="underline pointer ml-2"
                  >
                    Need help?
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </Layout>
  )
}
